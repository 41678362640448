import React, { useEffect, useState, useRef, useMemo } from 'react'
import Navbar from './../../components/Navbar'
import TopBar from './../../components/topbar'
import Footer from './../../layout/footer'
import useQuery from '../../hooks/useQuery'
import { propertyService } from '../../_services'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { FaBed } from 'react-icons/fa'
import moment from 'moment'
import { getFormattedDate } from '../../_helpers/utils'
import { DateRange, Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const checkInitialDate = dd => {
    if (moment().isAfter(moment(dd))) {
        return moment(new Date()).toDate()
    } else return dd
}

const BookingAvailability = () => {
    const query = useQuery()
    const today = new Date()
    const user = localStorage.usertoken
    const [bookingType, setBookingType] = useState()
    const [bookingTypeOptions, setBookingTypeOptions] = useState([])
    const [propertyList, setPropertyList] = useState([])
    const [properyDetails, setPropertyDetails] = useState()
    const [selectedRoom, setSelectedRoom] = useState()
    const [selectedBed, setSelectedBed] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [isBlocking, setIsBlockingOrUnblocking] = useState(true)
    const [isRendering, setIsReRendering] = useState(false)
    const [visitDate, setVisitDate] = useState([
        {
            startDate: checkInitialDate(moment(query.get('checkIn') || new Date()).toDate()),
            endDate: checkInitialDate(moment(query.get('checkOut') || moment(new Date()).add(1, 'day')).toDate()),
            key: 'selection',
        },
    ])
    const [coldbedSelectedBlockingDate, setColdbedSelectedBlockingDate] = useState(
        checkInitialDate(moment(query.get('checkIn') || new Date()).toDate())
    )

    const bedsContainer = useRef()

    useEffect(() => {
        // fetch all host properties
        propertyService.allList().then(
            objs => {
                setPropertyList(objs.data)
            },
            error => {
                toast.error('Something went wrong while fetching properties.')
            }
        )
    }, [])

    const onChangeBookingType = async bookingTypeValue => {
        setBookingType(bookingTypeValue)
        await fetchPropertyDetails(properyDetails._id, { bookingType: bookingTypeValue }, false)
    }

    // fetch property details on select property
    const fetchPropertyDetails = (propertyId, options, isReset = true) => {
        propertyService
            .getDetail(propertyId, options)
            .then(async res => {
                setIsReRendering(false)
                setPropertyDetails(res.data)
                if (isReset) {
                    if (res.data.bookingType === 'Flexible') {
                        setBookingTypeOptions(['Cold bed', 'Hot bed'])
                        setBookingType('Cold bed')
                    }
                    if (res.data.bookingType === 'Cold bed') {
                        setBookingTypeOptions(['Cold bed'])
                        setBookingType('Cold bed')
                    }
                    if (res.data.bookingType === 'Hot bed') {
                        setBookingTypeOptions(['Hot bed'])
                        setBookingType('Hot bed')
                    }
                    setSelectedRoom({
                        index: 0,
                        isAvailable: res.data.sleepingArrangements[0].isAvailable,
                        roomDetails: res.data.sleepingArrangements[0],
                    })
                }

                // Set the selected bed based on previous state or default to first bed
                setSelectedBed(prevSelectedBed => {
                    const previousBed = prevSelectedBed && prevSelectedBed.bedNumber // If there's a previously selected bed

                    const foundBed = res.data.sleepingArrangements.flatMap(room => room.beds).find(bed => bed.bedNumber === previousBed)

                    return foundBed || res.data.sleepingArrangements[0].beds[0] // Reset to 0 index if no previous bed
                })

                setIsBlockingOrUnblocking(true)
                setIsReRendering(true)
                // await resetDateSelection()
            })
            .catch(() => {
                toast.error('Something went wrong while fetching property details')
            })
    }

    const handlePropertyListing = async value => {
        await resetDateSelection()
        const selectedPropertyBookingType = await propertyList.find(item => {
            return item.id === value
        }).bookingType

        if (selectedPropertyBookingType === 'Flexible') {
            setBookingTypeOptions(['Cold bed', 'Hot bed'])
            setBookingType('Cold bed')
            await fetchPropertyDetails(value, { bookingType: 'Cold bed' })
        }

        if (selectedPropertyBookingType === 'Cold bed') {
            setBookingTypeOptions(['Cold bed'])
            setBookingType('Cold bed')
            await fetchPropertyDetails(value, { bookingType: 'Cold bed' })
        }

        if (selectedPropertyBookingType === 'Hot bed') {
            setBookingTypeOptions(['Hot bed'])
            setBookingType('Hot bed')
            await fetchPropertyDetails(value, { bookingType: 'Hot bed' })
        }
    }

    // changing selected room here
    const handleRoomSelection = type => {
        if (type === 'next') {
            setSelectedRoom(e => {
                const ind = Math.min(e.index + 1, properyDetails.sleepingArrangements.length - 1)
                return {
                    index: ind,
                    isAvailable: properyDetails.sleepingArrangements[ind].isAvailable,
                    roomDetails: properyDetails.sleepingArrangements[ind],
                }
            })
        } else {
            setSelectedRoom(e => {
                const ind = Math.max(e.index - 1, 0)
                return {
                    index: ind,
                    isAvailable: properyDetails.sleepingArrangements[ind].isAvailable,
                    roomDetails: properyDetails.sleepingArrangements[ind],
                }
            })
        }
        setSelectedBed(null)
    }

    const scrollLeftHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft - 88,
            behavior: 'smooth',
        })
    }

    const scrollRightHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft + 88,
            behavior: 'smooth',
        })
    }

    const confirmBlockUnblock = () => {
        const startDate = moment(visitDate[0].startDate).startOf('day')
        const endDate = moment(visitDate[0].endDate).endOf('day')

        // Convert bookedDates to moment objects
        const bookedDates = (selectedBed.bookedDates.length > 0 && selectedBed.bookedDates.map(date => moment(date).startOf('day'))) || []

        // Check if selected dates are equal to or after any booked dates
        const isDateAfterOrEqual = bookedDates.some(
            bookedDate => startDate.isSameOrAfter(bookedDate, 'day') || endDate.isSameOrAfter(bookedDate, 'day')
        )

        // Check if any booked date overlaps with the selected date range
        const isDateOverlapping = bookedDates.some(bookedDate => bookedDate.isBetween(startDate, endDate, 'day', '[)'))

        if (isDateAfterOrEqual && bookingType === 'Cold bed') {
            // Show error message if the condition is met
            toast.error('You cannot block dates that are equal to or after any booked dates!')
            return
        }

        if (isDateOverlapping && bookingType === 'Hot bed') {
            toast.error('You cannot block dates that overlap with or are the same as any booked dates!')
            return
        }

        const bedDetails = {
            bookingType: bookingType,
            propertyId: properyDetails._id,
            listingId: properyDetails.listingId,
            hostId: properyDetails.addedBy,
            selectedRoom: selectedRoom.roomDetails._id,
            selectedBed: selectedBed._id,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            propertyType: properyDetails.rentalType,
            isBlocking: isBlocking,
        }

        if (bookingType === 'Cold bed') {
            bedDetails.coldBedblockedDate = moment(coldbedSelectedBlockingDate).format('YYYY-MM-DD')
        }

        propertyService
            .blockUnblockedBeds(bedDetails)
            .then(res => {
                if (res.statusCode === 200) {
                    if (isBlocking) toast.success('Blocked Successfully!')
                    if (!isBlocking) toast.success('Unblocked Successfully!')
                    fetchPropertyDetails(properyDetails._id, { bookingType: bookingType }, false)
                }
            })
            .catch(() => {
                toast.error('Something went wrong while fetching property details')
            })
    }

    const BedCard = ({ data, selected, onClick, disabled, unSelectable }) => {
        return (
            <span
                onClick={disabled || unSelectable ? null : onClick}
                className={`tw-relative ${
                    disabled
                        ? `tw-cursor-not-allowed tw-p-4 tw-bg-gray-200 tw-text-sm tw-shadow-md tw-rounded tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[100px] tw-flex-shrink-0`
                        : `tw-p-4 ${
                              unSelectable ? '' : 'hover:tw-bg-parrot-light hover:tw-text-white'
                          } tw-text-sm tw-shadow-md tw-rounded tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[100px] tw-flex-shrink-0 ${
                              selected ? 'tw-bg-parrot-light tw-text-white' : ''
                          } `
                }`}
            >
                <p>{data.bedNumber}</p>
                <FaBed size={24} />
                <p className="tw-text-xs tw-whitespace-normal">{data.bedType.split(' ')[0]}</p>
            </span>
        )
    }

    const isDisableBlockedDate = data => {
        if (data && data.blockedDates && data.blockedDates.length > 0) {
            return data.blockedDates.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
        }
        return []
    }

    const isDisabledBookedDates = data => {
        if (data && data.bookedDates && data.bookedDates.length > 0) {
            return data.bookedDates.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
        }
        return []
    }

    const isDisabledAutoBlockedDates = data => {
        if (data && data.autoBlocked && data.autoBlocked.length > 0) {
            return data.autoBlocked.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
        }
        return []
    }

    const splitIntoRanges = dates => {
        // Convert date strings to moment objects for easier manipulation
        const sortedDates = dates.map(date => moment(date)).sort((a, b) => a - b)

        const ranges = []
        let currentRange = [sortedDates[0]]

        for (let i = 1; i < sortedDates.length; i++) {
            const previousDate = sortedDates[i - 1]
            const currentDate = sortedDates[i]

            // Check if the current date is contiguous with the previous date
            if (currentDate.diff(previousDate, 'days') === 1) {
                currentRange.push(currentDate)
            } else {
                // End of the current range, push to ranges and start a new range
                ranges.push(currentRange.map(date => date.format('YYYY-MM-DD')))
                currentRange = [currentDate]
            }
        }

        // Push the last range
        if (currentRange.length > 0) {
            ranges.push(currentRange.map(date => date.format('YYYY-MM-DD')))
        }

        return ranges
    }

    const generateDateRange = (blockFromDate, blockToDate) => {
        const dates = []
        const today = new Date()
        today.setHours(0, 0, 0, 0) // Normalize currentDate to start of the day

        let startDate = new Date(blockFromDate)
        let endDate = new Date(blockToDate)

        // Adjust startDate and endDate based on today's date
        if (startDate <= today) {
            startDate = new Date(today)
        }

        if (endDate <= today) {
            endDate = new Date(today)
        }

        // Generate dates between the adjusted startDate and endDate
        while (startDate <= endDate) {
            dates.push(new Date(startDate).toISOString().split('T')[0])
            startDate.setDate(startDate.getDate() + 1)
        }

        return dates
    }

    // Function to generate styles dynamically
    // Memoize getDayStyle to avoid unnecessary recalculations
    const getDayStyle = useMemo(
        () => date => {
            const dateStr = moment(date).format('YYYY-MM-DD')
            const blockedDates = isDisableBlockedDate(selectedBed) || []
            const bookedDates = isDisabledBookedDates(selectedBed) || []
            const autoBlockedDates = isDisabledAutoBlockedDates(selectedBed) || []

            const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
            const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

            const individualDate = new Date(dateStr)
            const selectedStartDate = new Date(selectedStartDateStr)
            const selectedEndDate = new Date(selectedEndDateStr)
            const today = new Date()
            const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

            const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

            if (isWithinSelectedRange) {
                return {
                    zIndex: '-1',
                    color: 'white',
                }
            } else {
                if (bookingType === 'Cold bed') {
                    const blockRanges =
                        (sortedBlockedDates.length > 0 &&
                            generateDateRange(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) ||
                        []
                    const bookedRange =
                        (sortedBookedDates.length > 0 && generateDateRange(sortedBookedDates[0], sortedBookedDates[sortedBookedDates.length - 1])) ||
                        []
                    const autoBlockedRage = (sortedAutoBlockedDates.length > 0 && sortedAutoBlockedDates) || []
                    if (autoBlockedRage.includes(dateStr)) {
                        return {
                            width: '100%',
                            background: '#f8f8f8',
                            color: 'black',
                            zIndex: '0',
                        }
                    }

                    if (bookedRange.includes(dateStr)) {
                        return {
                            width: '100%',
                            background: '#f8f8f8',
                            color: 'black',
                            zIndex: '0',
                        }
                    }

                    if (blockRanges.includes(dateStr) && !autoBlockedRage.includes(dateStr) && !bookedRange.includes(dateStr)) {
                        return {
                            width: '100%',
                            background: '#f48889',
                            color: 'white',
                            zIndex: '0',
                            borderRadius: '18px',
                        }
                    }
                } else {
                    const blockRanges = sortedBlockedDates.length > 0 ? splitIntoRanges(sortedBlockedDates) : []
                    const bookedRanges = sortedBookedDates.length > 0 ? splitIntoRanges(sortedBookedDates) : []
                    const autoBlockedRages = sortedAutoBlockedDates.length > 0 ? splitIntoRanges(sortedAutoBlockedDates) : []
                    for (const autoBlockedRage of autoBlockedRages) {
                        if (autoBlockedRage.includes(dateStr)) {
                            return {
                                width: '100%',
                                background: '#f8f8f8',
                                color: 'black',
                                zIndex: '0',
                            }
                        }
                    }

                    for (const bookedRange of bookedRanges) {
                        if (bookedRange.includes(dateStr)) {
                            return {
                                width: '100%',
                                background: '#f8f8f8',
                                color: 'black',
                                zIndex: '0',
                            }
                        }
                    }

                    for (const range of blockRanges) {
                        if (range.length == 1 && range.includes(dateStr)) {
                            return {
                                width: '100%',
                                background: '#f48889',
                                color: 'white',
                                zIndex: '0',
                                borderRadius: '18px',
                            }
                        }

                        if (range.length > 1 && range.includes(dateStr)) {
                            const isStart = dateStr === range[0]
                            const isEnd = dateStr === range[range.length - 1]

                            return {
                                width: '100%',
                                background: '#f48889',
                                color: 'white',
                                zIndex: '0',
                                borderRadius: isStart ? '18px 0px 0px 18px' : isEnd ? '0px 18px 18px 0px' : '0px',
                            }
                        }
                    }
                }
            }
        },
        [bookingType, visitDate, selectedBed]
    )

    const updateToUnblock = date => {
        const blockedDates = isDisableBlockedDate(selectedBed) || []
        const bookedDates = isDisabledBookedDates(selectedBed) || []
        const autoBlockedDates = isDisabledAutoBlockedDates(selectedBed) || []

        const dateStr = moment(date).format('YYYY-MM-DD')
        const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
        const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

        // Parse date strings to Date objects for range comparison
        const individualDate = new Date(dateStr)
        const selectedStartDate = new Date(selectedStartDateStr)
        const selectedEndDate = new Date(selectedEndDateStr)
        const coldBedSelected = new Date(coldbedSelectedBlockingDate)

        const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
        const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
        const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

        if (bookingType === 'Cold bed') {
            const blocRanges =
                (sortedBlockedDates.length > 0 && generateDateRange(sortedBlockedDates[0], sortedBlockedDates[sortedBlockedDates.length - 1])) || []
            
            if (
                (sortedBlockedDates.includes(dateStr) && !sortedAutoBlockedDates.includes(dateStr) && !sortedBookedDates.includes(dateStr)) ||
                moment(individualDate).format('YYYY-MM-DD') == moment(coldBedSelected).format('YYYY-MM-DD')
            ) {
                return true
            } else if (sortedAutoBlockedDates.includes(dateStr)) {
                return false
            } else if (blocRanges.includes(dateStr)) {
                return false
            } else if (sortedBookedDates.includes(dateStr)) {
                return false
            } else if (sortedAutoBlockedDates.includes(dateStr)) {
                return false
            } else {
                return false
            }
        } else {
            if (blockedDates && blockedDates.includes(dateStr) && individualDate >= selectedStartDate && individualDate <= selectedEndDate) {
                return true
            } else if (blockedDates && blockedDates.includes(dateStr)) {
                return true
            } else if (sortedBookedDates.includes(dateStr)) {
                return false
            } else if (sortedAutoBlockedDates.includes(dateStr)) {
                return false
            } else {
                return false
            }
        }
    }

    const handleOnChangeColdbedDates = coldBedBlockDate => {
        // Fetch blocked dates
        const blockedDates = isDisableBlockedDate(selectedBed)
        const autoBlockedDates = isDisabledAutoBlockedDates(selectedBed)

        // Check if coldBedBlockDate is defined
        if (!coldBedBlockDate) {
            return // Exit the function if startDate is missing
        }

        // Format the start date for comparison
        const startDate = moment(coldBedBlockDate).format('YYYY-MM-DD')

        // Check if startDate is in blockedDates or autoBlockedDates
        const isStartDateBlocked = blockedDates.includes(startDate)
        const isStartDateAutoBlocked = autoBlockedDates.includes(startDate)
        // Set visitDate's endDate to one day before the coldBedBlockDate date
        const previousDate = moment(coldBedBlockDate)
            .subtract(1, 'days')
            .toDate()

        if (!isStartDateAutoBlocked) {
            if (isStartDateBlocked) {
                setIsBlockingOrUnblocking(!isStartDateBlocked)
            } else {
                setIsBlockingOrUnblocking(true)
            }
            setColdbedSelectedBlockingDate(coldBedBlockDate)
            setVisitDate([
                {
                    startDate: checkInitialDate(moment(query.get('checkIn') || new Date()).toDate()),
                    endDate: previousDate,
                },
            ])
        }
    }

    const handleOnChangeDates = e => {
        // Fetch blocked dates
        const blockedDates = isDisableBlockedDate(selectedBed)
        const bookedDates = isDisabledBookedDates(selectedBed)
        const autoBlockedDates = isDisabledAutoBlockedDates(selectedBed)

        // Check if e.selection.startDate and e.selection.endDate are defined
        if (!e.selection || !e.selection.startDate || !e.selection.endDate) {
            return // Exit the function if startDate or endDate is missing
        }

        // Function to check if any date in a range is in autoBlockedDates
        const isDateInRangeAutoBlocked = (start, end, autoBlockedDates) => {
            let currentDate = moment(start)
            while (currentDate <= moment(end)) {
                if (autoBlockedDates.includes(currentDate.format('YYYY-MM-DD'))) {
                    return true
                }
                currentDate = currentDate.add(1, 'days')
            }
            return false
        }
        // Function to check if any date in a range is in blockedDates
        const isDateInRangeBlocked = (start, end, blockedDates) => {
            let currentDate = moment(start)
            while (currentDate <= moment(end)) {
                if (blockedDates.includes(currentDate.format('YYYY-MM-DD'))) {
                    return true
                }
                currentDate = currentDate.add(1, 'days')
            }
            return false
        }

        // Check if both start and end dates are in blockedDates
        const bothDatesBlocked = isDateInRangeBlocked(e.selection.startDate, e.selection.endDate, blockedDates)
        // Update state based on whether both dates are blocked
        setIsBlockingOrUnblocking(!bothDatesBlocked)
        // Handle Cold bed booking differently
        if (bookingType === 'Cold bed') {
            let startDate = autoBlockedDates.length
                ? new Date(
                      new Date(autoBlockedDates[autoBlockedDates.length - 1]).setDate(
                          new Date(autoBlockedDates[autoBlockedDates.length - 1]).getDate() + 1
                      )
                  )
                : new Date()

            let endDate = new Date(e.selection.endDate)

            if (isDateInRangeAutoBlocked(startDate, endDate, autoBlockedDates)) {
                const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                startDate = moment(sortedAutoBlockedDates[sortedAutoBlockedDates.length - 1])
                    .add(1, 'days')
                    .toDate()
            }
            if (bookedDates.includes(moment(startDate).format('YYYY-MM-DD'))) {
                while (bookedDates.includes(moment(startDate).format('YYYY-MM-DD'))) {
                    startDate = moment(startDate)
                        .add(1, 'days')
                        .toDate()
                }
            }

            setVisitDate([
                {
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection',
                },
            ])
        } else {
            setVisitDate([
                {
                    startDate: new Date(e.selection.startDate),
                    endDate: new Date(e.selection.endDate),
                    key: 'selection',
                },
            ])
        }
    }

    const getSystemTimezoneOffset = () => {
        const offset = new Date().getTimezoneOffset()
        const absOffset = Math.abs(offset)
        const hours = String(Math.floor(absOffset / 60)).padStart(2, '0')
        const minutes = String(absOffset % 60).padStart(2, '0')
        const sign = offset > 0 ? '-' : '+'

        return `${sign}${hours}:${minutes}`
    }

    const resetDateSelection = () => {
        if (selectedBed) {
            const timezone = getSystemTimezoneOffset()
            setSelectedBed(prev => {
                // Check if the selected bed is different from the previous one
                if (prev && prev._id !== selectedBed._id) {
                    // If the selected bed is different, set the start date to the current date
                    const currentDate = new Date()
                    const currentDateString = `${moment(currentDate).format('YYYY-MM-DD')}T00:00:00${timezone}`
                    const newStartDate = new Date(currentDateString)

                    // Update the state for the visit date and start date
                    setVisitDate([
                        {
                            startDate: newStartDate,
                            endDate: newStartDate,
                            key: 'selection',
                        },
                    ])
                    setStartDate(newStartDate)
                } else {
                    // If the selected bed is the same and has autoBlocked dates, get the last blocked date
                    const sortedBlockedDates = selectedBed.autoBlocked.map(d => moment(d).format('YYYY-MM-DD')).sort() || []
                    const lastBlockedDateStr =
                        sortedBlockedDates.length > 0 ? sortedBlockedDates[sortedBlockedDates.length - 1] : moment().format('YYYY-MM-DD')
                    const dateString = `${lastBlockedDateStr}T00:00:00${timezone}`
                    const lastBlockedDate = new Date(dateString)

                    // Set the start date to the day after the last blocked date
                    const newStartDate = new Date(lastBlockedDate)
                    newStartDate.setDate(lastBlockedDate.getDate() + 1)

                    // Update the state for the visit date and start date
                    setVisitDate([
                        {
                            startDate: newStartDate,
                            endDate: newStartDate,
                            key: 'selection',
                        },
                    ])
                    setStartDate(newStartDate)
                    setColdbedSelectedBlockingDate(newStartDate)
                }

                // Return the current selected bed to retain in state
                return selectedBed
            })
        }
    }

    useEffect(() => {
        resetDateSelection()
    }, [selectedBed, bookingType]) // Re-run the effect when blockedDates changes

    function getDisabledDates() {
        const disabledDate =
            selectedBed && Array.isArray(selectedBed.bookedDates)
                ? selectedBed.bookedDates.map(item => {
                      const timezone = getSystemTimezoneOffset()
                      const dateString = `${item}T00:00:00${timezone}`
                      const date = new Date(dateString)
                      return date
                  })
                : []

        return disabledDate
    }

    return (
        <>
            <div id="main-wrapper">
                <TopBar user={user} title="Booking Calendar" />
                <div className="deznav">
                    <div className="deznav-scroll">
                        <Navbar />
                    </div>
                </div>

                <ToastContainer />
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="container-booking-availability tw-flex tw-flex-row tw-gap-x-10">
                            {/* container 1 */}
                            <div className="container1 tw-flex tw-flex-col tw-w-full">
                                <div
                                    className="cs-card"
                                    style={{
                                        padding: '12px',
                                    }}
                                >
                                    <div className="">
                                        <div className="tw-flex tw-items-center tw-justify-between tw-m-4">
                                            <p className="tw-text-primary">Properties</p>
                                            <div className="tw-w-[50%]">
                                                <select
                                                    className="booking_type_dropdown tw-w-full tw-cursor-pointer"
                                                    name="property_list"
                                                    required
                                                    onChange={e => handlePropertyListing(e.target.value)}
                                                >
                                                    <option value="">Select Property</option>
                                                    {propertyList.length > 0 &&
                                                        propertyList.map((item, index) => (
                                                            <option value={item.id} key={`${item.id}-${index}`}>
                                                                {item.listingName}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        {bookingTypeOptions.length >= 0 ? (
                                            <div className="tw-flex tw-items-center tw-justify-between tw-m-4">
                                                <p className="tw-text-primary">Booking Type</p>
                                                {bookingTypeOptions.length >= 2 && (
                                                    <div className="tw-w-[1rem]">
                                                        <select
                                                            className="booking_type_dropdown tw-cursor-pointer"
                                                            name="booking_type"
                                                            required
                                                            value={bookingType}
                                                            onChange={e => {
                                                                onChangeBookingType(e.target.value)
                                                            }}
                                                        >
                                                            <option value="">Select booking type</option>
                                                            {bookingTypeOptions.length >= 0 &&
                                                                bookingTypeOptions.map((item, index) => (
                                                                    <option value={item} key={`${item}-${index}`}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                )}
                                                {bookingTypeOptions.length == 1 && (
                                                    <div className="tw-w-[1rem]">
                                                        <div className="booking_type_dropdown tw-cursor-pointer tw-text-black">
                                                            {bookingTypeOptions[0]}
                                                        </div>
                                                        {/* <select
                                                            className="booking_type_dropdown tw-cursor-pointer"
                                                            name="booking_type"
                                                            required
                                                            disabled
                                                            value={bookingType}
                                                            onChange={e => {
                                                                onChangeBookingType(e.target.value)
                                                            }}
                                                        >
                                                            {bookingTypeOptions.length >= 0 &&
                                                                bookingTypeOptions.map((item, index) => (
                                                                    <option value={item} key={`${item}-${index}`}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                        </select> */}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="tw-flex tw-items-center tw-justify-between tw-m-4"></div>
                                        )}
                                        {properyDetails && selectedRoom && (
                                            <div className="tw-m-4">
                                                <div>
                                                    {bookingType === 'Hot bed' && (
                                                        <div className="tw-my-4">
                                                            <div className="tw-mx-auto tw-w-max">
                                                                <DateRange
                                                                    rangeColors={['#23426E']}
                                                                    minDate={new Date()}
                                                                    maxDate={new Date(today.getFullYear(), today.getMonth() + 2, 0)}
                                                                    className="tw-w-full"
                                                                    editableDateInputs={false}
                                                                    onChange={e => {
                                                                        const { selection } = e
                                                                        const startDate = new Date(selection.startDate)
                                                                        const today = new Date()
                                                                        if (startDate < today) {
                                                                            e.startDate = today
                                                                        }
                                                                        handleOnChangeDates(e)
                                                                    }}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={visitDate}
                                                                    disabledDates={getDisabledDates()}
                                                                    dayContentRenderer={date => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ ...getDayStyle(date) }}>{date.getDate()}</div>
                                                                                <div
                                                                                    className="temp-date"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        color: updateToUnblock(date) ? 'white' : 'black',
                                                                                    }}
                                                                                >
                                                                                    {date.getDate()}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {bookingType === 'Cold bed' && (
                                                        <div className="tw-my-4">
                                                            <div className="tw-mx-auto tw-w-max">
                                                                <Calendar
                                                                    color={'#23426E'}
                                                                    minDate={new Date()}
                                                                    maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)}
                                                                    date={coldbedSelectedBlockingDate}
                                                                    onChange={e => {
                                                                        handleOnChangeColdbedDates(e)
                                                                    }}
                                                                    className="tw-w-full"
                                                                    disabledDates={getDisabledDates()}
                                                                    dayContentRenderer={date => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ ...getDayStyle(date) }}>{date.getDate()}</div>
                                                                                <div
                                                                                    className="temp-date"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        color: updateToUnblock(date) ? 'white' : 'black',
                                                                                    }}
                                                                                >
                                                                                    {date.getDate()}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <p className="tw-text-primary">{properyDetails && properyDetails.rentalType==='Crashpad'?"Bed Selection":'Room Selection'}   </p>
                                                    <div className="tw-flex tw-justify-between tw-items-center tw-py-8">
                                                        <span
                                                            onClick={() => handleRoomSelection('prev')}
                                                            className="tw-flex-shrink-0 tw-mr-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center "
                                                        >
                                                            <HiOutlineChevronLeft size={24} className="tw-cursor-pointer" />
                                                        </span>
                                                        <hr className="tw-border-dashed tw-border-gray-400 tw-mx-auto tw-w-full" />
                                                        <p className="tw-text-center tw-w-full tw-mx-2 tw-whitespace-nowrap">
                                                            Bedroom{' '}
                                                            {properyDetails &&
                                                                properyDetails.sleepingArrangements.length &&
                                                                properyDetails.sleepingArrangements[selectedRoom.index].roomNumber}
                                                        </p>
                                                        <hr className="tw-border-dashed tw-border-gray-400 tw-mx-auto tw-w-full" />
                                                        <span
                                                            onClick={() => handleRoomSelection('next')}
                                                            className="tw-flex-shrink-0 tw-ml-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                                                        >
                                                            <HiOutlineChevronLeft
                                                                size={24}
                                                                className="tw-transform tw-rotate-180 tw-cursor-pointer"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="tw-relative">
                                                        {/* {!selectedRoom.isAvailable && (
                                                            <div className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 tw-rounded tw-bg-red-400 tw-bg-opacity-80 tw-text-white tw-grid tw-place-content-center">
                                                                <p className="tw-text-xl tw-uppercase tw-transform tw-rotate-45">Reserved</p>
                                                            </div>
                                                        )} */}
                                                        <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-text-xs tw-text-gray-500 tw-text-center">
                                                            {properyDetails.rentalType === 'Crashpad' && (
                                                                <>
                                                                    <p className="tw-col-span-1">
                                                                        <span>Room Type </span> <br />
                                                                        <span className="tw-font-medium tw-text-primary">
                                                                            {(properyDetails &&
                                                                                properyDetails.sleepingArrangements.length &&
                                                                                properyDetails.sleepingArrangements[selectedRoom.index].roomType) ||
                                                                                '--'}
                                                                        </span>
                                                                    </p>
                                                                    <p className="tw-col-span-1">
                                                                        <span>Bathroom </span> <br />
                                                                        <span className="tw-font-medium tw-text-primary">
                                                                            {(properyDetails &&
                                                                                properyDetails.sleepingArrangements.length &&
                                                                                properyDetails.sleepingArrangements[selectedRoom.index]
                                                                                    .numberOfBathroom) ||
                                                                                '--'}
                                                                        </span>
                                                                    </p>
                                                                    <p className="tw-col-span-1">
                                                                        <span>Gender </span> <br />
                                                                        <span className="tw-font-medium tw-text-primary">
                                                                            {(properyDetails &&
                                                                                properyDetails.sleepingArrangements.length &&
                                                                                properyDetails.sleepingArrangements[selectedRoom.index].genderType) ||
                                                                                '--'}
                                                                        </span>
                                                                    </p>
                                                                    <p className="tw-col-span-1">
                                                                        <span> Profession</span>
                                                                        <br />
                                                                        <span className="tw-font-medium tw-text-primary ">
                                                                            {(properyDetails &&
                                                                                properyDetails.sleepingArrangements.length &&
                                                                                properyDetails.sleepingArrangements[selectedRoom.index].crewType) ||
                                                                                '--'}
                                                                        </span>
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="">
                                                            <div className="tw-flex tw-justify-between tw-mt-3 tw-items-center">
                                                                <span
                                                                    onClick={scrollLeftHandlerForBed}
                                                                    className="tw-flex-shrink-0 tw-mr-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                                                                >
                                                                    <HiOutlineChevronLeft size={24} className="tw-cursor-pointer" />
                                                                </span>
                                                                <div
                                                                    ref={bedsContainer}
                                                                    className="tw-flex tw-justify-center tw-items-center tw-w-full tw-gap-2 tw-flex-nowrap tw-p-4 tw-px-2 tw-overflow-scroll tw-scrollbar-hide"
                                                                >
                                                                    {properyDetails &&
                                                                        properyDetails.sleepingArrangements.length &&
                                                                        properyDetails.sleepingArrangements[selectedRoom.index].beds.map(
                                                                            (el, ind) => {
                                                                                return (
                                                                                    <React.Fragment key={ind}>
                                                                                        <BedCard
                                                                                            unSelectable={properyDetails.rentalType === 'Entire Place'}
                                                                                            data={el}
                                                                                            selected={selectedBed && properyDetails.rentalType !== 'Entire Place' && selectedBed._id === el._id}
                                                                                            onClick={() => {
                                                                                                setSelectedBed(el)
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        )}
                                                                </div>
                                                                <span
                                                                    onClick={scrollRightHandlerForBed}
                                                                    className="tw-flex-shrink-0 tw-ml-2 tw-hover:tw-bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                                                                >
                                                                    <HiOutlineChevronLeft
                                                                        size={24}
                                                                        className="tw-transform tw-rotate-180 tw-cursor-pointer"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <button
                                            disabled={!selectedBed}
                                            className={`tw-p-4 tw-text-center ${
                                                !selectedBed ? 'tw-bg-gray-500' : 'tw-bg-primary'
                                            } tw-text-white tw-rounded-md tw-w-full tw-text-lg tw-font-medium`}
                                            onClick={confirmBlockUnblock}
                                        >
                                            {isBlocking ? 'Confirm Block Night(s)' : 'Confirm Unblocked Night(s)'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* container 2 */}
                            <div className="container2 tw-flex tw-flex-col tw-w-full tw-gap-4">
                                <div
                                    className="cs-card"
                                    style={{
                                        padding: '24px',
                                    }}
                                >
                                    <div className="tw-mb-4">
                                        <p className="tw-text-primary tw-font-bold">Hot Bed Only</p>
                                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                                            <span
                                                className="tw-bg-primary bullet"
                                                style={{
                                                    borderRadius: '9999px',
                                                    marginTop: '6px',
                                                }}
                                            ></span>
                                            <p className="tw-text-primary">
                                                When hot bed dates are blocked, those selected dates for hot bed will be blocked.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tw-mb-4">
                                        <p className="tw-text-primary tw-font-bold">Cold Bed Only</p>
                                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                                            <span
                                                className="tw-bg-primary bullet"
                                                style={{
                                                    borderRadius: '9999px',
                                                    marginTop: '6px',
                                                }}
                                            ></span>
                                            <p className="tw-text-primary">
                                                When a cold bed date is blocked on the calendar, all prior dates for cold bed will be blocked.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="tw-text-primary tw-font-bold">Flexible Booking</p>
                                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                                            <span
                                                className="tw-bg-primary bullet"
                                                style={{
                                                    borderRadius: '9999px',
                                                    marginTop: '6px',
                                                }}
                                            ></span>
                                            <p className="tw-text-primary">
                                                When a cold bed date is blocked on the calendar, all prior dates for cold bed will be blocked.
                                            </p>
                                        </div>
                                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                                            <span
                                                className="tw-bg-primary bullet"
                                                style={{
                                                    borderRadius: '9999px',
                                                    marginTop: '6px',
                                                }}
                                            ></span>
                                            <p className="tw-text-primary">
                                                When hot bed dates are blocked, those selected dates for hot bed will be blocked.
                                            </p>
                                        </div>
                                        <div className="tw-ml-4">
                                            <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                                                <span
                                                    className="tw-bg-primary bullet-2"
                                                    style={{
                                                        borderRadius: '9999px',
                                                        marginTop: '6px',
                                                    }}
                                                ></span>
                                                <p className="tw-text-primary">
                                                    When hot bed dates are blocked, all earlier dates up to the furthest checkout date will be
                                                    automatically blocked for cold bed.
                                                </p>
                                            </div>
                                            <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                                                <span
                                                    className="tw-bg-primary bullet-2"
                                                    style={{
                                                        borderRadius: '9999px',
                                                        marginTop: '6px',
                                                    }}
                                                ></span>
                                                <p className="tw-text-primary">
                                                    Example: hot bed dates blocked: 8/20/24-8/28/24 and 9/3/24-9/7/24. Everything prior to 9/7/24 will
                                                    be blocked for cold bed.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="cs-card"
                                    style={{
                                        padding: '38px 24px 38px 24px',
                                    }}
                                >
                                    <div className="tw-flex tw-flex-row tw-gap-2">
                                        <span>
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                                        <p className="tw-text-primary tw-mb-4">
                                            Pro Tip: Onboard all current tenants to pay through the portal for best results and automated bed
                                            availability.
                                        </p>
                                    </div>
                                    <p className="tw-text-primary">
                                        Properties with manually blocked dates may be ranked lower in search results and receive fewer booking
                                        requests.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default BookingAvailability
